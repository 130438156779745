import axios from "~/plugins/axios";

export default {
  getPaymentTypeList() {
    var fakeResult = {
      response: {
        status: 0,
        errors: [],
        infos: [],
        warnings: [],
        errorsMessage: [],
        infosMessage: [],
        warningsMessage: []
      },

      data: {
        payments: [
          {
            paymentTypeId: 11,
            active: 1,
            name: "BONIFICO BANCARIO",
            paymentName: "Delayed",
            descr:
              "<H3       style='color: var(--v-primary-base);'  >IBAN TIGROS: BPER BANCA: <strong>IT 33 K 05387 50140 0000 0427 9249</strong></H3>",
            gatewayClient: "payment-type-delivery-melaregalo",
            metaData: {}
          },
          {
            paymentTypeId: 12,
            active: 1,
            name: "Online CON CARTA DI CREDITO",
            paymentName: "Monetaweb",
            descr:
              "<strong       style='color: var(--v-primary-base);' >Per inserire la carta di credito verrai indirizzato nel sito sicuro di Intesa Sanpaolo.<br>Attendi qualche secondo per il completamento della procedura, nel frattempo non chiudere la pagina.</strong> ",
            gatewayClient: "payment-type-monetaweb-melaregalo",
            metaData: {}
          }
        ],
        alipayments: [
          {
            paymentTypeId: 2,
            active: 1,
            name: "Bonifico",
            paymentName: "Wire Transfer",
            descr: "",
            gatewayClient: "payment-type-delivery-giftcard-ali",
            metaData: {}
          },
          {
            paymentTypeId: 10,
            active: 1,
            name: "Online CON CARTA DI CREDITO",
            paymentName: "argentea",
            descr: "",
            gatewayClient: "payment-type-argentea-giftcard-ali",
            metaData: {}
          }
        ]
      }
    };

    return fakeResult;
  },
  getPaymentsAuth(paymentTypeId) {
    return axios
      .get("/ebsn/api/payment-type/list-auth", {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data.auths;
      });
  },
  deletePaymentAuth(paymentAuthId) {
    return axios
      .post("/ebsn/api/payment-type/delete-auth", null, {
        params: { payment_auth_id: paymentAuthId }
      })
      .then(data => {
        return data.data.data.auths;
      });
  },
  addAuth(paymentTypeId, paymentData) {
    return axios
      .post("/ebsn/api/payment-type/add-auth", null, {
        params: {
          payment_type_id: paymentTypeId,
          payment_data: JSON.stringify(paymentData)
        }
      })
      .then(data => {
        return data.data.data;
      });
  }
};
