<template>
  <div class="products category-med">
    <Breadcrumb
      :items="category.navigationTypeId != 0 ? breadcrumbs : breadcrumb"
    />

    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container class="py-0">
      <CategoryTitle
        :category="category"
        :count="count"
        :selectedCategory="selectedCategory"
      />
      <div v-if="category.children" class="subcategories">
        <v-chip-group show-arrows>
          <v-chip
            v-for="subCategory in category.children"
            :key="subCategory.categoryId"
            label
            link
            exact-active-class="secondary"
            :to="
              category.navigationTypeId == 0
                ? { name: 'Category', params: { pathMatch: subCategory.slug } }
                : {
                    name: 'Category',
                    params: {
                      pathMatch: category.slug
                    },
                    query: { categoryId: subCategory.categoryId }
                  }
            "
            class="mr-2 mb-2 rounded-lg primary--text primary lighten-2"
          >
            {{ subCategory.name }}
          </v-chip>
        </v-chip-group>
      </div>
    </v-container>
    <v-container>
      <ProductListGrid
        :parentCategoryId="category.categoryId"
        :categoryId="categoryId"
        :key="categoryId || category.categoryId"
        hideFilterString="Categorie,Main Term"
        @productsCount="updateCount"
        itemListName="Category Med"
      />
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <!-- <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      /> -->

      <div class="category-footer d-flex mb-2">
        <span v-html="visibleText"></span>
        <span v-if="!isExpanded && hiddenText">
          <span class="collapsed">{{ hiddenText }}</span>
        </span>
      </div>
      <v-btn
        small
        depressed
        outlined
        color="primary"
        class="mb-3"
        v-if="showButton"
        @click="toggleExpand"
        >{{ isExpanded ? $t("common.showLess") : $t("common.showMore") }}</v-btn
      >
    </v-container>
  </div>
</template>
<style lang="scss">
.collapsed {
  display: none;
}
.show-more-bullets {
  font-weight: 500;
}
</style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixins from "~/mixins/category";
import toNumber from "lodash/toNumber";
import get from "lodash/get";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  data() {
    return { count: -1, categoryId: null, isExpanded: false, wordLimit: 22 };
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    },
    fullText() {
      return get(
        this.category,
        "metaData.category_info.FOOTER_DESCRIPTION",
        ""
      );
    },
    visibleText() {
      const words = this.fullText.split(" ");
      if (words.length > this.wordLimit && !this.isExpanded) {
        return (
          words.slice(0, this.wordLimit).join(" ") +
          " <span class='show-more-bullets'>...</span>"
        );
      }
      return this.fullText;
    },
    hiddenText() {
      const words = this.fullText.split(" ");
      if (words.length > this.wordLimit && !this.isExpanded) {
        return words.slice(this.wordLimit).join(" ");
      }
      return "";
    },
    showButton() {
      return this.fullText.split(" ").length > this.wordLimit;
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    }
  },
  created() {
    this.categoryId = toNumber(this.$route.query.categoryId);
  },
  watch: {
    async "$route.query.categoryId"() {
      this.categoryId = this.$route.query.categoryId;
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              this.breadcrumb[this.breadcrumb.length - 2]?.text
            ])
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.category, "metaData.category_seo.SEO_KEYWORDS")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          property: "og:type",
          content: "product.group"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              this.breadcrumb[this.breadcrumb.length - 2]?.text
            ])
          )
        },
        {
          property: "og:site_name",
          content: "Tigros"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
