var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products category-med"},[_c('Breadcrumb',{attrs:{"items":_vm.category.navigationTypeId != 0 ? _vm.breadcrumbs : _vm.breadcrumb}}),_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"position1"}}),_c('v-container',{staticClass:"py-0"},[_c('CategoryTitle',{attrs:{"category":_vm.category,"count":_vm.count,"selectedCategory":_vm.selectedCategory}}),(_vm.category.children)?_c('div',{staticClass:"subcategories"},[_c('v-chip-group',{attrs:{"show-arrows":""}},_vm._l((_vm.category.children),function(subCategory){return _c('v-chip',{key:subCategory.categoryId,staticClass:"mr-2 mb-2 rounded-lg primary--text primary lighten-2",attrs:{"label":"","link":"","exact-active-class":"secondary","to":_vm.category.navigationTypeId == 0
              ? { name: 'Category', params: { pathMatch: subCategory.slug } }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: _vm.category.slug
                  },
                  query: { categoryId: subCategory.categoryId }
                }}},[_vm._v(" "+_vm._s(subCategory.name)+" ")])}),1)],1):_vm._e()],1),_c('v-container',[_c('ProductListGrid',{key:_vm.categoryId || _vm.category.categoryId,attrs:{"parentCategoryId":_vm.category.categoryId,"categoryId":_vm.categoryId,"hideFilterString":"Categorie,Main Term","itemListName":"Category Med"},on:{"productsCount":_vm.updateCount}})],1),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2"}}),_c('category-block',{staticClass:"category-block category-block-3",attrs:{"target":_vm.category,"position":"position3"}}),_c('v-container',[_c('div',{staticClass:"category-footer d-flex mb-2"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.visibleText)}}),(!_vm.isExpanded && _vm.hiddenText)?_c('span',[_c('span',{staticClass:"collapsed"},[_vm._v(_vm._s(_vm.hiddenText))])]):_vm._e()]),(_vm.showButton)?_c('v-btn',{staticClass:"mb-3",attrs:{"small":"","depressed":"","outlined":"","color":"primary"},on:{"click":_vm.toggleExpand}},[_vm._v(_vm._s(_vm.isExpanded ? _vm.$t("common.showLess") : _vm.$t("common.showMore")))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }