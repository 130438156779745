<template>
  <div
    v-if="category"
    class="category-title"
    :class="{
      'w-100 d-flex flex-column justify-center text-center': centered,
      'd-flex flex-column flex-md-row': showHeaderImg
    }"
  >
    <ebsn-style :target="category" path="category_advanced.CSS_STYLE" />
    <v-img
      v-if="showHeaderImg && headerImage"
      :src="headerImage"
      :height="height"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'"
      class="mr-sm-5"
      @load="loaded = true"
      :alt="category.name"
    ></v-img>
    <div
      class="d-flex align-center flex-row flex-wrap default--text"
      :class="{ 'justify-center': centered, 'sr-only': hideTitle }"
    >
      <v-btn
        v-if="selectedCategory"
        icon
        :to="{ name: 'Category', params: { pathMatch: category.slug } }"
        exact
        ><v-icon>$prev</v-icon></v-btn
      >
      <ebsn-meta
        :target="selectedCategory ? selectedCategory : category"
        path="category_info.TITLE"
        tag="h1"
        :default-value="
          selectedCategory ? selectedCategory.name : category.name
        "
        :style="{ color: $ebsn.meta(category, 'category_info.COLOR') }"
      ></ebsn-meta>
      <strong class="ml-4" v-if="count > 0"> ({{ count }} prodotti) </strong>
    </div>
    <div class="description mb-2">
      <span v-html="visibleText"></span>
      <span v-if="!isExpanded && hiddenText">
        <span class="collapsed">{{ hiddenText }}</span>
      </span>
    </div>
    <v-btn
      small
      depressed
      outlined
      color="primary"
      class="mb-3"
      v-if="showButton"
      @click="toggleExpand"
      >{{ isExpanded ? $t("common.showLess") : $t("common.showMore") }}</v-btn
    >
  </div>
</template>
<style lang="scss">
.category-title {
  p > a {
    color: var(--v-default-base) !important;
  }
}
.collapsed {
  display: none;
}
.show-more-bullets {
  font-weight: 500;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";

export default {
  name: "CategoryTitle",
  props: {
    count: { type: Number, required: false },
    category: { type: Object, required: true },
    selectedCategory: { type: Object, required: false },
    height: { type: String },
    centered: { type: Boolean, default: false },
    showHeaderImg: { type: Boolean, default: false }
  },
  mixins: [clickHandler],
  data() {
    return { loaded: false, isExpanded: false, wordLimit: 22 };
  },
  computed: {
    fullText() {
      return get(this.category, "metaData.category_info.DESCRIPTION", "");
    },
    visibleText() {
      const words = this.fullText.split(" ");
      if (words.length > this.wordLimit && !this.isExpanded) {
        return (
          words.slice(0, this.wordLimit).join(" ") +
          " <span class='show-more-bullets'>...</span>"
        );
      }
      return this.fullText;
    },
    hiddenText() {
      const words = this.fullText.split(" ");
      if (words.length > this.wordLimit && !this.isExpanded) {
        return words.slice(this.wordLimit).join(" ");
      }
      return "";
    },
    showButton() {
      return this.fullText.split(" ").length > this.wordLimit;
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>
